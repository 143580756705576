import React, { useEffect } from "react";
import ThankYou from "../Components/Assets/thankYou.webp"

function Thankyou() {
    useEffect(() => {
        setTimeout(() => {
            localStorage.clear()
            window.location = "/"
        }, 8000)
    }, [])
    return (
        <div>
            <div className="h-[100vh] bg-primary center">
                <div>
                    <img src={ThankYou} className="h-[50vh]" alt="thankyou" />
                    <h1 className="text-[64px] text-[#fff] thankyou">Thank you</h1>
                    <div className="center">
                        <a href="https://rvscops.ac.in/" target="_blank"  rel="noreferrer">
                            <button className="bg-white flex py-1 thankyou hover:bg-primary hover:text-white px-16 text-h1 underline hover:  text-text-color mt-4  borderradius">
                                {/* <HiOutlineGlobe className="my-1 ml-4 mr-2 text-primary"/> */}
                                Visit our website</button>
                        </a>
                    </div>
                </div>


            </div>
            {/* <h1>Thank You </h1> */}
        </div>
    )
}

export default Thankyou