import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import NeedImage from '../Assets/needimage.png'
import Axxelent from '../Assets/placement/Axxelent.jpg'
import CaplinPoinc from '../Assets/placement/Caplin Poinc.jpg'
import Medreich from '../Assets/placement/Medreich.jpg'
import MicroLab from '../Assets/placement/Micro Lab.jpg'
import Myva from '../Assets/placement/myva.jpg'
import Pharmafabrikon from '../Assets/placement/Pharmafabrikon.jpg'

function InternShip() {
    const optionss = {
        items: 1,
        nav: true,
        navText: ["<div className='nav-btn prev-slide left-arrow arrow-button'><i className='arrow-left fa-solid fa-angle-left'><</i></div>", "<div className='nav-btn next-slide'><i className='arrow-left fa-solid fa-angle-right'>></i></div>"],
        rewind: true,
        autoplay: true,
        dots: true,
        dotsEach: true,
        dotData: true,
        responsive: {
            0: {
                items: 1
            },
            480: {
                items: 2
            },
            769: {
                items: 5
            }
        }
    };
    return (
        <div className="container mx-auto pb-10">
            <div className="flex sm:block w-[100%]">


                <div className="w-[100%]   sm:block">
                    <div className="title pb-3 sm:text-[24px] mdsm:text-[30px] sm:px-4 text-secondary tracking-wider text-center lg:text-[40px]">
                    Placement and Internships
                    </div>
                    {/* <p className='text-primary text-h5 text-center text-left  sm-text-[14px] pb-4'>Students get to work on summer internships for eight weeks with core companies related to the specialization. Some of the companies are
                    </p> */}
                    <div className="w-[100%] flex sm:p-4   sm:block lg:flex mdsm:block">
                        <div className="w-[100%] flex sm:w-[100%] lg:w-[100%] mdsm:w-[100%]">
                            <OwlCarousel className="owl-theme" {...optionss}>

                                <div className='item'>
                                    <div className='my-4 px-4'>
                                        <img src={Axxelent} alt="propel" />
                                    </div>
                                </div>

                                <div className='item '>
                                    <div className='my-4 px-4'>
                                        <img src={CaplinPoinc} alt="SIMTA" />
                                    </div>
                                </div>


                                <div className='item'>
                                    <div className='my-4 px-4'>
                                        <img src={Medreich} alt="TT" />
                                    </div>
                                </div>


                                <div className='item'>
                                    <div className='my-4 px-4'>
                                        <img src={MicroLab} alt="ShakthiKnitting" />
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='my-4 px-4'>
                                        <img src={Myva} alt="ShakthiKnitting" />
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='my-4 px-4'>
                                        <img src={Pharmafabrikon} alt="ShakthiKnitting" />
                                    </div>
                                </div>

                                {/* <div className="item ">
                    <div className='w-[100%]  flex sm:block'>
                        <div className="w-[50%] sm:w-[100%]">
                            <div className=" mr-8">
                                <div className="block py-4">
                                    <div className="">
                                        <img src={Arun} alt="Arun" className="h-[300px] object-cover w-[100%]" />
                                    </div>
                                    <div >

                                        <p className="text-h2 text-pureWhite sm:text-left  text-left pt-10  title">Arun  </p>
                                        <p className="text-[16px] text-pureWhite text-left sm:text-left">Transform Tech ,Sulur, Coimbatore</p>
                                        <p className="text-pureWhite pt-3 px-8 text-left sm:text-left">
                                            ₹ 4.25 lakhs per annum
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-[50%] sm:w-[100%]">
                            <div className=" mr-8">
                                <div className="block py-4">
                                    <div className="">
                                        <img src={Ganesh} alt="Ganesh" className="h-[300px] object-cover w-[100%]" />
                                    </div>
                                    <div >
                                        <p className="text-h2 text-pureWhite  text-left pt-10 sm:text-left title">Ganesh </p>
                                        <p className="text-[16px] text-pureWhite text-left sm:text-left">Info Tech , Coimbatore</p>
                                        <p className="text-pureWhite pt-3 px-8 text-left sm:text-left">
                                            ₹ 3.25 lakhs per annum
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div className="item ">
                    <div className='w-[100%]  flex sm:block'>
                        <div className="w-[50%] sm:w-[100%]">
                            <div className=" mr-8">
                                <div className="block py-4">
                                    <div className="">
                                        <img src={Jiva} alt="Jiva" className="h-[300px] object-cover w-[100%]" />
                                    </div>
                                    <div >

                                        <p className="text-h2 text-pureWhite sm:text-left  text-left pt-10  title">Jiva </p>
                                        <p className="text-[16px] text-pureWhite text-left sm:text-left">OneWhistle Inc, San Francisco, USA</p>
                                        <p className="text-pureWhite pt-3 px-8 text-left sm:text-left">
                                            ₹ 3.25 lakhs per annum
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-[50%] sm:w-[100%]">
                            <div className=" mr-8">
                                <div className="block py-4">
                                    <div className="">
                                        <img src={Dhinesh} alt="Dhinesh" className="h-[300px] object-cover w-[100%]" />
                                    </div>
                                    <div >
                                        <p className="text-h2  text-pureWhite  text-left pt-10 sm:text-left title">Dhinesh </p>
                                        <p className="text-[16px] text-pureWhite text-left sm:text-left">OneWhistle Inc, San Francisco, USA</p>
                                        <p className="text-pureWhite pt-3 px-8 text-left sm:text-left">
                                            ₹ 2.25 lakhs per annum
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div> */}



                            </OwlCarousel>






                            <div>

                            </div>
                        </div>
                        {/* <div className="w-[40%] py-8 sm:w-[100%] lg:w-[40%] mdsm:w-[100%]">
            <div className="title pb-3 text-pureWhite tracking-wider text-left text-[32px]">
                Internship
            </div>
            <div className="w-[100%]">
                <div className=" mr-8">
                    <div className="flex  py-4">
                        <div className="sm:w-[40%] mdsm:w-[20%] lg:w-[30%]">
                            <img src={Gokul} alt="Gokul" className="h-[150px] mdsm:w-[100%] sm:w-[100%]" />
                        </div>
                        <div className='sm:w-[60%] mdsm:w-[80%] lg:w-[70%] sm:px-4'>
                            <p className="text-h2 text-pureWhite sm:text-[18px] sm:mx-1 sm:pt-6  text-left pt-4 mx-8 title">{process.env.REACT_APP_TITLE} </p>
                            <p className="text-[16px] text-pureWhite mx-8 sm:text-sm mdsm:text-left sm:text-left sm:mx-1">Info Tech , Coimbatore</p>
                            <p className="text-pureWhite pt-3 px-8 text-left sm:text-sm sm:text-left sm:mx-1 sm:px-2  mx-8">
                                ₹ 1 lakhs per annum
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-[100%]">
                <div className=" mr-8">
                    <div className="flex  py-4">
                        <div className="sm:w-[40%] mdsm:w-[20%] lg:w-[30%]">
                            <img src={Thiru} alt="Thiru" className="h-[150px] mdsm:w-[100%] sm:w-[100%]" />
                        </div>
                        <div className='sm:w-[60%] mdsm:w-[80%] lg:w-[70%] sm:px-4'>
                            <p className="text-h2  text-pureWhite sm:text-[18px] sm:mx-1 sm:pt-6  text-left pt-4 mx-8 title">Thiru </p>
                            <p className="text-[16px] text-pureWhite mx-8 sm:text-sm mdsm:text-left sm:text-left sm:mx-1">Info Tech , Coimbatore</p>
                            <p className="text-pureWhite pt-3 px-8 text-left sm:text-sm sm:text-left sm:mx-1 sm:px-2  mx-8">
                                ₹ 1 lakhs per annum
                            </p>
                        </div>
                    </div>
                </div>
            </div>


        </div> */}
                    </div>

                </div>




            </div>
        </div>
    )
}

export default InternShip