
import React from "react";
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import Home from './Pages/Home';
import "./Components/css/components.css"
import "./Components/css/responsive.css"
import CoursePage from './Pages/CoursePage';
import Thankyou from "./Pages/Thankyou";
import PageNotFound from "./Pages/PageNotFound";

// import Spinner from "./Components/Hub-Components/Spinner";
function App() {
  // const [spinnerState, setSpinnerState] = useState(false);
  setTimeout(() => {
    // alert("Hello")
    // setSpinnerState(true)
    // console.log("Delayed for 1 second.");
  }, 0)
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<CoursePage />} />
          {!localStorage.Mpahrmacy ? <></> : <Route path="/thankyou" element={<Thankyou />} />}
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
